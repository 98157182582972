import React from 'react'
import SEO from '../components/seo'
import Container from '../components/layout/layout'
import Layout from '../containers/layout'
import { graphql } from 'gatsby'
import VideoBanner from '../components/video-banner/video-banner'

export const query = graphql `
  query NotFoundPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)notfound/" }) {
			id
      title
      _rawBody
			_rawSubtitle
      useVideo
      pageBranding {
        asset {
          _id
        }
      }
      mainVideo {
        embed {
          _type
          asset {
            url
          }
        }
      }
      mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
    }
  }
`
const NotFoundPage = (props) => {
	const { data, errors } = props
	const dataCheck = typeof data === "object";
	let page = {};
	if(dataCheck && Object.keys(data).length > 0) {
		page = data.page;
	} 

  if (errors && (Object.keys(errors).length > 0 || errors.length > 0 )) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (page && Object.keys(page).length < 1) {
     throw new Error(
       'Missing "Not Found" page data. Open the studio at http://localhost:3333 and add "Not Found" page data and restart the development server.'
     )
  }
 return (
    <Layout>
      <SEO title='Page Not found' />
			<VideoBanner { ...page}/>
    </Layout>
  )
}

export default NotFoundPage
